<template>
  <div>
    <Breadscrumbs :items="breadcrumbs" class="header-bread-scrum" />
    <v-layout column>
      <v-layout class="d-flex">
        <v-layout class="d-flex">
          <v-layout class="mr-2">
            <v-text-field
              v-model="namePrice"
              outlined
              dense
              clearable
              style="width: 190px"
              :maxlength="maximumSmallText"
              :placeholder="$t('Nhập tên bảng giá')"
              @change="namePrice = trimSpace(namePrice)"
              @keydown.enter="searchPrice"
            />
          </v-layout>
          <v-layout v-if="lstPostOffice.length" class="mr-2 col-3 px-0 py-0">
            <v-autocomplete
              v-model="selectPostOffice"
              outlined
              dense
              clearable
              hide-details
              :filter="filterItems"
              :no-data-text="$t('no-data')"
              :placeholder="$t('place_holders.choice-post-office')"
              :items="lstPostOffice"
              item-text="name"
              item-value="id"
            />
          </v-layout>
          <v-layout>
            <v-select
              v-model="partnerSelect"
              outlined
              dense
              item-text="partnerName"
              item-value="partnerId"
              style="width: 180px"
              :items="lstPartner"
              :placeholder="$t('Chọn đơn vị vận chuyển')"
              @change="onChangePartnerAll"
            />
          </v-layout>
          <v-layout class="ml-2">
            <v-select
              v-if="onChangeSelect"
              v-model="serviceSelect"
              outlined
              dense
              item-text="description"
              item-value="id"
              style="width: 180px"
              :items="lstService"
              :placeholder="$t('Chọn dịch vụ giao hàng')"
            />
          </v-layout>
        </v-layout>
        <v-layout justify-end class="mb-3">
          <v-btn color="primary" @click="searchPrice">
            {{ $t("search") }}
          </v-btn>
        </v-layout>
      </v-layout>
      <v-layout justify-end class="mb-3">
        <v-btn color="primary" @click="onGotoShopPrices">
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
          {{ $t("price_list") }}
        </v-btn>
      </v-layout>
      <v-data-table
        class="border-primary"
        hide-default-footer
        :no-data-text="$t('not_found')"
        :headers="headers"
        :header-props="{ sortIcon: 'mdi-menu-swap' }"
        :items="items"
        height="70vh"
        @click:row="onGotoShopUpdatePrices"
      >
        <template v-slot:[`item.name`]="{ item }">
          <tr class="max-width-6 text-truncate">
            {{
              item.name
            }}
          </tr>
          <tr>
            <span v-if="item.isDefault === 1" class="default-price">
              [{{ $t("default") }}]
            </span>
          </tr>
        </template>
        <template v-slot:[`item.partnerId`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ item.partnerName }}
          </td>
        </template>
        <template v-slot:[`item.serviceId`]="{ item }">
          <td class="max-width-6 text-truncate">
            {{ item.serviceDescription }}
          </td>
        </template>
        <template v-slot:[`item.updatedAt`]="{ item }">
          {{ formatDate(item.updatedAt) }}
        </template>
        <template v-slot:footer>
          <custom-pagination
            :page="page"
            :start-record="computedStartRecord"
            :end-record="computedEndRecord"
            :total-record="totalRecord"
            :total-page="totalPage"
            :on-change-page="onChangePage"
          />
        </template>
      </v-data-table>

      <!-- Dialog loading -->
      <dialog-loading v-model="showLoading" :message="$t('processing')" />

      <!-- notifications -->
      <dialog-notification
        v-model="showNoti"
        :icon-src="getIcon"
        :message="message"
      />

      <dialog-confirm-token-expried v-model="showConfirmTokenExpried" />
    </v-layout>
  </div>
</template>

<script>
import Breadscrumbs from "@/components/base/Breadscrumbs";
import DialogLoading from "@/components/dialog/DialogLoading.vue";
import DialogNotification from "@/components/dialog/DialogNotification.vue";
import DialogConfirmTokenExpried from "@/components/dialog/DialogConfirmTokenExpried.vue";
import CustomPagination from "@/components/CustomPagination.vue";
import constants from "@/constants";
import filterItems from "@/helpers/filterItems";
import { StorageService } from "@/services/storageService";
import { DateTimeUtils } from "@/helpers/dateTimeUtils.js";
import routePaths from "@/router/routePaths.js";
import { StringUtils } from "@/helpers/stringUtils";
import { PricesService } from "@/services/pricesService";
import { mapActions } from "vuex";
import { DataUtils } from "@/helpers/dataUtils";
import { PartnerService } from "@/services/partnerService";
import { PostService } from "@/services/postService";
import { BaseService } from "@/services/baseService";

export default {
  components: {
    DialogLoading,
    DialogNotification,
    DialogConfirmTokenExpried,
    CustomPagination,
    Breadscrumbs,
  },
  mixins: [filterItems],
  data() {
    return {
      items: [],

      // loading
      showLoading: false,
      showConfirmTokenExpried: false,
      // notifications
      showNoti: false,
      typeIcon: constants.typeAlert.warning,
      message: "",
      maximumSmallText: constants.maximumSmallText,
      namePrice: "",
      headers: [
        {
          text: this.$t("name_prices"),
          value: "name",
          sortable: true,
          class: "text-subtitle-2",
        },
        {
          text: this.$t("group-shipping"),
          value: "partnerId",
          class: "text-subtitle-2",
          sortable: false,
        },
        {
          text: this.$t("ship_service"),
          value: "serviceId",
          sortable: false,
          class: "text-subtitle-2",
        },
        {
          text: this.$t("post_offices"),
          value: "poName",
          sortable: true,
          class: "text-subtitle-2",
        },
        {
          text: this.$t("updated_by"),
          value: "updatedByName",
          sortable: false,
          class: "text-subtitle-2",
        },
        {
          text: this.$t("updated_date"),
          value: "updatedAt",
          sortable: false,
          class: "text-subtitle-2",
        },
      ],
      lstPartner: [],
      partnerSelect: null,
      serviceSelect: null,
      totalPage: 0,
      page: 1,
      totalRecord: 0,
      onChangeSelect: false,
      btnSearch: false,
      lstService: [],
      persistent: false,
      typeNoti: constants.typeAlert.warning,
      currentRole: JSON.parse(localStorage.getItem("CURRENT_USER")).roles,
      breadcrumbs: [
        {
          text: "shop_price_list",
          disabled: true,
          isActive: true,
        },
      ],
      lstPostOffice: [],
      selectPostOffice: null,
    };
  },
  computed: {
    getIcon() {
      return StringUtils.getIconSrc(this.typeIcon);
    },
    computedStartRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.pageSize + 1;
      } else {
        return 0;
      }
    },
    computedEndRecord() {
      if (this.items !== null && this.items.length > 0) {
        return (this.page - 1) * constants.pageSize + this.items.length;
      } else {
        return (this.page - 1) * constants.pageSize;
      }
    },
  },
  created() {
    if (
      window.location.pathname &&
      !this.currentRole.includes("ROLE_ADMIN") &&
      !this.currentRole.includes("ROLE_ROOT")
    ) {
      this.toggleDialogNoti({
        state: true,
        msg: this.$t("no-mission-msg"),
      });
      setTimeout(() => {
        this.$router.go(-1);
      }, constants.timeOutRole);
    }
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    ...mapActions("layout", ["setBreadcrumbs"]),
    async initData() {
      await Promise.all([this.getPrices(), this.getPartners()]);
      this.searchPostOffice();
    },
    async getPartners() {
      try {
        this.lstPartner = [];
        const { status, data } = await BaseService.getLogisticPartner();
        if (
          status === constants.statusCode.ok &&
          data !== null &&
          data.length > 0
        ) {
          this.lstPartner = data.filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.partnerId === item.partnerId)
          );
          this.lstPartner.unshift({
            partnerId: null,
            partnerName: "Tất cả",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    toggleDialogNoti(
      pars = {
        state: false,
        persistent: false,
        type: constants.typeAlert.warning,
        msg: "",
      }
    ) {
      this.showNoti = pars.state;
      this.persistent = pars.persistent;
      this.typeNoti = pars.type;
      this.message = pars.msg;
    },
    onChangePartnerAll() {
      this.onChangeSelect = true;
      this.serviceSelect = null;
      if (this.partnerSelect !== null) {
        this.getServiceAll();
      } else {
        this.onChangeSelect = false;
        this.serviceSelect = null;
      }
    },
    async getServiceAll() {
      try {
        const response = await PartnerService.getServiceAll();
        if (this.partnerSelect === constants.partnerGroup.ninjaVan) {
          this.lstService = response.data.filter(
            (item) => item.deliveryPartnerId === constants.partnerGroup.ninjaVan
          );
        } else {
          this.lstService = response.data.filter(
            (item) => item.deliveryPartnerId === constants.partnerGroup.ghtk
          );
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getPrices() {
      this.showLoading = true;
      const pars = {
        currentPage: this.page,
        limit: constants.pageSize,
        partnerId: this.partnerSelect,
        serviceId: this.serviceSelect,
        name: this.namePrice ? this.namePrice.trim() : "",
      };
      if (this.selectPostOffice) {
        pars["poId"] = this.selectPostOffice;
      } else {
        pars["poId"] = null;
      }
      const { status, data } = await PricesService.getShopPrices(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        this.totalRecord = data.totalRecord;
        this.totalPage = DataUtils.customerCalculatePage(data.totalRecord);
        if (this.totalRecord > 0) {
          this.items = data.data;
        } else {
          this.items = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      }
    },
    searchPrice() {
      this.page = 1;
      this.btnSearch = true;
      this.getPrices();
    },
    async searchPostOffice() {
      this.showLoading = true;
      const pars = this.bindRequestPars();
      const { status, data } = await PostService.getPostOffice(pars);
      this.showLoading = false;
      if (status === constants.statusCode.ok && data) {
        if (data.totalRecord > 0) {
          this.lstPostOffice = data.data;
        } else {
          this.lstPostOffice = [];
        }
      } else if (StorageService.checkResponseExpried(status, data)) {
        this.showConfirmTokenExpried = true;
      } else {
        this.toggleDialogNoti({ state: true, msg: data.message });
      }
    },
    bindRequestPars() {
      const pars = {
        keyword: "",
        status: 1,
        limit: 1000,
        currentPage: this.page,
      };
      return pars;
    },
    onGotoShopPrices() {
      this.$router.push(routePaths.SHOP_CREATE_OR_UPDATE_PRICES);
    },
    onGotoShopUpdatePrices(item) {
      this.$router.push({
        path: routePaths.SHOP_CREATE_OR_UPDATE_PRICES,
        query: { detailIdPrice: item.id, partnerId: item.partnerId },
      });
    },
    onChangePage(page) {
      this.page = page;
      if (this.btnSearch === true) {
        this.getPrices();
      } else {
        this.selectPostOffice = null;
        this.namePrice = "";
        this.getPrices();
      }
    },
    trimSpace(val) {
      return val ? val.trim() : "";
    },
    formatDate(val) {
      return DateTimeUtils.formatDateTime(val, constants.formatDateTimeHm);
    },
  },
};
</script>

<style src="@/styles/form.scss" lang="scss" />
